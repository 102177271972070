import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAIL,
    CLEAR_ERRORS
} from '../constants/notificationConstants';

export const getNotificationsReducer = (state = {notifications: [], loading: true}, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST:
            return{
                ...state,
                loading: true
            }
        case GET_NOTIFICATIONS_SUCCESS:
            return{
                loading: false,
                notifications: action.payload
            }
        case GET_NOTIFICATIONS_FAIL:
            return{
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return{
                ...state,
                error: null
            }
        default:
            return state;
    }
}