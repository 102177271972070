import Iframe from 'react-iframe'

function PDFViewer(props) {

  return (
    <div>
      <div>
        <Iframe url={props.pdfUrl} width="100%" height="600px"/>
      </div>
    </div>
  );
}

export default PDFViewer;